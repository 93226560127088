<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header ml-4">
            <signer-info :signer="signer"/>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="section-header">
                  <button class="btn btn-primary" type="button" @click="signReport" v-if="isSign">
                    Hisobotni imzolash
                  </button>
                </div>
                <div class="row pr-5">
                  <div class="col-12 text-left ml-4">
                    <label><b>Imzolash uchun tekst</b></label>
                    <div class="mt-3 mb-3 border p-2">{{signDataForReport.signedDocument.signText}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignerInfo from "../../components/sign/signer-info"
import EIMZOClient from '@/shared/sign/vue-e-imzo-client'
import SIGN from "../../shared/sign/sign-document"

export default {
  name: "test",
  components: {
    SignerInfo
  },
  data() {
    return {
      loading: false,
      signer: null,
      signDataForReport: {
        uuid: null,
        pkcs7: null,
        keyId: null,
        signedDocument: {
          signText: 'Test',
          espStatus: 'PENDING'
        },
        signRequest: {
          data: {
            TIN: null,
            PINFL: null
          }
        },
        isSigned: false
      }
    }
  },
  computed: {
    isKeyIdRequired() {
      return this.signDataForReport.keyId
    },
    isPkcs7Required() {
      return this.signDataForReport.pkcs7
    },
    isSign() {
      return this.signer && !this.signer.vo.expired && !this.signDataForReport.isSigned &&
          this.signDataForReport.signedDocument.signText && this.signDataForReport.signedDocument.signText !== ''
    }
  },
  methods: {
    sign() {
      if (!this.isPkcs7Required){
        this.$swal({title: 'Diqqat!', text: 'Imzolashda xatolik. pkcs7 topilmadi!', type: 'warning'})
        return
      }

      this.$http.post('/sign', this.signDataForReport)
          .then(res => {
            if (res.data.status === 'BAD_REQUEST') {
              this.$swal({title: 'fail', text: res.data.message, type: 'warning'})
            } else {
              this.$swal({title: 'success', text: res.data.message, type: 'success'})
            }
            this.signDataForReport.isSigned = true
          }, err => {
            console.log(err)
          })
    },
    signReport() {
      const vm = this;

      if (!vm.isKeyIdRequired){
        EIMZOClient.loadKey(vm.signer.vo, function (id) {
          vm.signDataForReport.keyId = id
          EIMZOClient.createPkcs7(id, vm.signDataForReport.signedDocument.signText, null, function (pkcs7) {
            vm.signDataForReport.pkcs7 = pkcs7
            vm.sign()
          }, function (e, r) {
            SIGN.errorMessage(r, e)
          })
        }, function (e, r) {
          SIGN.errorMessage(r, e)
        })
      } else {
        EIMZOClient.createPkcs7(vm.signDataForReport.keyId, vm.signDataForReport.signedDocument.signText, null, function (pkcs7) {
          vm.signDataForReport.pkcs7 = pkcs7
          vm.sign()
        }, function (e, r) {
          SIGN.errorMessage(r, e)
        })
      }
    }
  },
  created() {

    let type = 'INDIVIDUAL';
    let tin = '517807932';
    this.signDataForReport.signedDocument.espType = 'APPRAISER_SIGN_REPORT'
    this.signDataForReport.signRequest.appName = 'E_BAHOLASH'
    this.signDataForReport.signRequest.data.PINFL = '41601953160037'
    this.signDataForReport.signRequest.data.TIN = '303178701'
    this.signDataForReport.signRequest.data.LOGIN_USER_ID = '1'
    this.signDataForReport.signRequest.entityId = '1'
    this.signDataForReport.signRequest.entityType = 'AUTO'
    this.signDataForReport.signRequest.instance = {
      name:'local',
      minioBucketName: 'evaluation'
    }
    if (this.$route.params.type === '2'){
      type = 'ENTITY';
      tin = '303178701'
      this.signDataForReport.signedDocument.espType = 'DIRECTOR_SIGN_REPORT'
      this.signDataForReport.signRequest.data.TIN = tin
    }

    SIGN.signInit()

    setTimeout(() => {
      this.signer = SIGN.singer(type, tin, this.signDataForReport.signRequest.data.PINFL)
    }, 1000)
  }
}
</script>

<style scoped>
#reportFrame, #reportFrameContract {
  width: 97%;
  height: 100%;
}

.frameHeight {
  height: 700px;
}

textarea {
  border: 1px solid #2b8dc6 !important;
  border-radius: 10px;
}
</style>